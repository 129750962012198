import { render, staticRenderFns } from "./Slider3d.vue?vue&type=template&id=d94a40fa&scoped=true&"
import script from "./Slider3d.vue?vue&type=script&lang=js&"
export * from "./Slider3d.vue?vue&type=script&lang=js&"
import style0 from "./Slider3d.vue?vue&type=style&index=0&id=d94a40fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d94a40fa",
  null
  
)

export default component.exports